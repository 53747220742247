import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/rileyuy/Documents/GitHub/ep-mg-website/src/components/Layout/Layout.js";
import SEO from "layout/SEO";
import HomeBanner from "components/StaticPages/Home/HomeBanner";
import HomeCard from "components/StaticPages/Home/HomeCard";
import HomeProcessDesktop from "components/StaticPages/Home/HomeProcessDesktop";
import HomeProcessMobile from "components/StaticPages/Home/HomeProcessMobile";
import Container from "layout/Container";
import ContactUs from "components/StaticPages/HelpCenter/ContactUs";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Home" mdxType="SEO" />
    <HomeBanner mdxType="HomeBanner" />
    <HomeProcessDesktop mdxType="HomeProcessDesktop" />
    <HomeProcessMobile mdxType="HomeProcessMobile" />
    <HomeCard mdxType="HomeCard" />
    <Container isCentered mdxType="Container">
  <ContactUs mdxType="ContactUs" />
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      